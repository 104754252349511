import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import './ServicesAll.scss'
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useNavigate } from 'react-router-dom';
import Chimney3 from '../../Images/PhotoImg3.jpg'
import bgnobgs from '../../Images/bgnobgs.png'
import AreaData from '..//ServicesPage/AreaData'

export default function ServicesAll() {
    const navigate = useNavigate()


    return (
        <div className="ServicesAll">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Air Duct Services | Pasadena Air Duct Cleaning - Cleaning, Inspection & Repair</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Air Duct Services | Pasadena Air Duct Cleaning - Cleaning, Inspection & Repair" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Air Duct Services | Pasadena Air Duct Cleaning - Cleaning, Inspection & Repair" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.pasadenaairduct.org/Reviews by our customers" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Explore the full range of professional Air Duct services offered by Pasadena Air Duct Cleaning. From cleaning and inspections to repairs and maintenance, we keep your Air Duct safe and efficient." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Explore the full range of professional Air Duct services offered by Pasadena Air Duct Cleaning. From cleaning and inspections to repairs and maintenance, we keep your Air Duct safe and efficient." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Air Duct Services | Pasadena Air Duct Cleaning - Cleaning, Inspection & Repair" data-react-helmet="true" />
                <meta name="keywords" content="Air Duct Cleaning, Air Duct Inspection, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct services, Air Duct service, Air Duct Inspection, Fireplace Inspection, Fireplace Cleaning, Air Duct Cleaning, Air Duct Maintenance, Fireplace Maintenance, Dryer Vent Cleaning, Air Duct Insulation, Air Duct Installation, Air Duct Liner Repair, Air Duct Pointing, Air Duct Rain Cap Installation, Air Duct Repair, Air Duct Crown Repair, Air Duct Restoration, Air Duct Flue Installation, Air Duct Flue Repair, ​Air Duct Construction, Air Duct Vent Installation, Air Duct Flashing, Flexible Air Duct Liner Installation, Air Duct Damper Repair, Air Duct Cap Repair, Fireplace Insulation, Fireplace Installation, Fireplace Liner Repair, Fireplace Pointing, Fireplace Rain Cap Installation, Fireplace Repair, Fireplace Crown Repair, Fireplace Restoration, Fireplace Flue Installation, Fireplace Flue Repair, Ffireplace Construction, Fireplace Vent Installation, Fireplace Flashing, Flexible fireplace Liner Installation, Fireplace Damper Repair, Fireplace Cap, Blog" data-react-helmet="true" />
            </Helmet>
            <div className="ServicesAllTitle">
                <h1>Comprehensive Air Duct Services in Pasadena, CA</h1>
                <h2>At Pasadena Air Duct Cleaning, we offer a full range of professional Air Duct services to keep your home safe, warm, and efficient. Whether you need a thorough Air Duct Cleaning, a detailed inspection, or expert repairs, our skilled technicians are here to provide top-quality service. From routine maintenance to addressing complex issues, we ensure your Air Duct is in optimal condition. Explore our services to find the right solution for your home, and trust Pasadena Air Duct Cleaning to deliver the excellence and reliability you deserve.</h2>
            </div>
            <div className="ServicesAllLsit">
                {AreaData.map((A, index) =>
                    <a href={A.Path} className="Header1BoxMenuBox">
                        
                        <div className="ServicesAllBox">
                            <h2>{A.Title}</h2>
                            <h6>{A.TitleShortVery}</h6>
                            <h4>{A.Full}</h4>
                            <h5>EXPLORE THIS SERVICE</h5>
                        </div>
                    </a>
                )}
            </div>

            <NavigatorPath />

        </div>
    )
}

